import type { PayloadLocale } from "@local/i10n/src/types"
import { fetchApi } from "@local/utils/src/fetchApi"
import { Payload } from "./types"
import { fetchRetry } from "@local/utils/src/fetchRetry"
import { safeJSONParse } from "@local/utils"
import { PHASE_PRODUCTION_BUILD } from "next/constants"

type FetcherProps = [
  endpoint: string,
  locale?: PayloadLocale,
  querystring?: string | null,
  options?: {
    flatten?: boolean
    tags?: Array<string>
    sign?: boolean
    noCache?: boolean
  },
]

const PAYLOADCMS_ENDPOINT =
  process.env.NEXT_PUBLIC_STAGE === "stg"
    ? "https://staging.cms.div.haus"
    : "https://cms.div.haus"
const PAYLOADCMS_CACHE_ENDPOINT =
  process.env.NODE_ENV === "development" ||
  process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD
    ? PAYLOADCMS_ENDPOINT
    : process.env.NEXT_PUBLIC_STAGE === "stg"
      ? "https://pyd2.div.haus"
      : "https://pyc2.div.haus"

export const fetchPayload = async <T>([
  endpoint,
  locale,
  querystring,
  options,
]: FetcherProps): Promise<T | null> => {
  let req: string | null | undefined = null

  const tags = options?.tags ? [endpoint, ...options?.tags] : [endpoint]

  try {
    const initBody = {
      method: "GET",
      next: {
        revalidate: 258000,
        tags,
        cache: "force-cache",
      },
      keepalive: true,
    } as RequestInit

    let finalEndpoint = endpoint

    if (options?.sign && process.env.HOOKDECK_SIG_SECRET) {
      initBody["headers"] = {
        "x-div-secret": process.env.HOOKDECK_SIG_SECRET,
      }
    }

    if (querystring) {
      finalEndpoint += `?${querystring.replace("?", "")}`
    }

    if (locale) {
      const firstCharacter = querystring ? "&" : "?"
      finalEndpoint += `${firstCharacter}locale=${locale}&fallback-locale=en`
    }

    const fullEndpoint = `${PAYLOADCMS_ENDPOINT}/api${finalEndpoint}`

    const cacheEndpoint = PAYLOADCMS_CACHE_ENDPOINT
      ? `${PAYLOADCMS_CACHE_ENDPOINT}/api${finalEndpoint}`
      : null

    // Tries to pull from Cache first
    if (cacheEndpoint && !options?.noCache) {
      req = await fetchRetry(cacheEndpoint, initBody, 1).then((res) =>
        res?.text()
      )
    }

    // Tries to pull from regular place then
    if (
      !req ||
      req.includes("no healthy upstream") ||
      req.includes("upstream connect error") ||
      req.includes("upstream request timeout") ||
      req.includes("<!DOCTYPE html>")
    ) {
      req = await fetchRetry(fullEndpoint, initBody, 1).then((res) =>
        res?.text()
      )
    }

    if (!req) {
      req = await fetchRetry(fullEndpoint, initBody, 2).then((res) =>
        res?.text()
      )

      if (!req) {
        return null
      }
    }
    let res = safeJSONParse<T | any>(req)

    if (options?.flatten) {
      if (res?.docs && res?.docs.length > 0) {
        res = res.docs[0]
      } else {
        return null
      }
    }

    if (res.error) {
      const error = new Error(res.error.message)
      console.error("contents", req)
      console.error(error)
      throw error
    }

    return res as T
  } catch (error) {
    console.error(error)
    return null
  }
}

export const fetchPayloadLocal = async <T>([
  endpoint,
  locale,
  querystring,
  options,
]: FetcherProps): Promise<T | null> => {
  let finalEndpoint = endpoint
  if (querystring) {
    finalEndpoint += `?${querystring.replace("?", "")}`
  }
  if (locale) {
    const firstCharacter = querystring ? "&" : "?"
    finalEndpoint += `${firstCharacter}locale=${locale}&fallback-locale=en`
  }
  const fullEndpoint = `/api/cms${finalEndpoint}`
  let res: any = await fetchApi<Payload.Query>({
    endpoint: fullEndpoint,
    method: "GET",
  })

  if (options?.flatten) {
    if (res?.docs && res?.docs.length > 0) {
      res = res.docs[0]
    } else {
      return null
    }
  }

  return res as T
}

export default fetchPayload
